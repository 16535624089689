.App {
  text-align: center;
}

.main-background {
  width: 100%;
  height: 70vh;
  position: relative;
}

.dropdown {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 50px;
  right: 90px;
  z-index: 1;
  width: 170px;
  color: black;
}

.workers {
  height: 50px;
  width: 50px;
}

/* Popup container */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 25px;
}

/* The actual popup (appears on top) */
.popup .popuptext {
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class when clicking on the popup container (hide and show the popup) */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}