#root {
  width: 100%;
  height: 100%;
  font-family: 'Courier New', Courier, monospace;
}

#nav {
  padding: 0 0 30px;
  text-align: center;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#nav a.isDisabled {
  color: #ddd;
}
button {
  background-color: black;
  color: white;
  border: none;
  box-shadow: 3px 5px 5px 3px;
  padding: 5px;
  border-radius: 3px;
}
button:hover {
  cursor: pointer;
  background-color: #3e8e41;
  color: white;
}

img:hover{
  cursor: pointer;
}